import { cx } from "@linaria/core";
import React, { FC, memo } from "react";
import { cssBoxActive, cssBoxDisabled, cssBoxImage, cssBoxText, cssVariationsBoxInner, VariationsBox, VariationsBoxList, VariationsSelectedName } from "@/components/Products/parts/Variations/StyledVariations";
import { VariationsViewPropsType } from "@/components/Products/parts/Variations/types";
import { getProductPath } from "@/hooks/product/helpers";
import { EntityImage } from "@/ui/EntityImage/EntityImage";
import { Link } from "@/ui/Link";
import { Loader } from "@/ui/Loaders/ComponentUiLoader/Loader";
import { Typography } from "@/ui/Typography/Typography";
const contentElement = ({
  image,
  name
}: {
  name: string;
  image?: string;
}) => <>
    <EntityImage imagePath={image} width={40} height={40} priority />
    <Typography variant={"span"}>{name}</Typography>
  </>;
const BoxedView: FC<VariationsViewPropsType> = memo(({
  items,
  selected,
  isFetching,
  onSelect,
  productName
}) => {
  return <>
        <VariationsSelectedName>
          {selected?.type}:{" "}
          <Typography variant={"span"}>{selected?.name}</Typography>
        </VariationsSelectedName>
        <VariationsBoxList>
          {isFetching && <Loader />}
          {items.map(({
        uuid = "",
        name = "",
        total_qty = 0,
        image = undefined,
        alias
      }) => {
        const isSelected = selected?.uuid === uuid;
        const disabled = !total_qty;
        return <VariationsBox key={uuid} className={cx(!!image ? cssBoxImage : cssBoxText, isSelected && cssBoxActive, disabled && cssBoxDisabled)}>
                  {!isSelected ? <Link href={getProductPath(alias)} title={productName} scroll onClick={e => {
            e.preventDefault();
            onSelect(uuid);
          }} className={cssVariationsBoxInner}>
                      {contentElement({
              image,
              name
            })}
                    </Link> : <div className={cssVariationsBoxInner}>
                      {contentElement({
              image,
              name
            })}
                    </div>}
                </VariationsBox>;
      })}
        </VariationsBoxList>
      </>;
});
BoxedView.displayName = "BoxedView";
export { BoxedView };