import { FC, memo, useCallback, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { BoxedView } from "@/components/Products/parts/Variations/BoxedView"
import { SelectView } from "@/components/Products/parts/Variations/SelectView"
import { VariationsPropsType } from "@/components/Products/parts/Variations/types"
import { ProductVariationType } from "@/components/Products/types"
import {
  getCurrentSpecification,
  getProductPath,
} from "@/hooks/product/helpers"
import { useAppSelector } from "@/hooks/redux"
import { StyledVariations } from "./StyledVariations"

const Variations: FC<VariationsPropsType> = memo(
  ({
    items,
    selected,
    limitShowBoxes,
    withAnimatingLabel,
    onSelectCb,
    isFetching = false,
    closeMode,
    product,
  }) => {
    const [selectedItem, setSelectedItem] = useState<
      undefined | ProductVariationType
    >(items.find((it) => it.alias === selected))
    const [isFetchingVariation, setIsFetchingVariation] =
      useState<boolean>(false)

    const specification = useAppSelector(({ cart }) => cart.specification)
    const router = useRouter()
    const productName = String(product?.name ?? "").replace(/["']/g, "")

    const inCart = useCallback(
      (uuid: string) =>
        Object.keys(
          getCurrentSpecification({
            uuid: uuid,
            specification: specification,
          }) || {},
        ).length > 0,
      [specification],
    )

    const onSelectHandle = useCallback(
      (uuid: string) => {
        if (selectedItem?.uuid === uuid) {
          return
        }

        const selectedVariation = items.find((v) => v.uuid === uuid)
        if (!selectedVariation) {
          return
        }

        setIsFetchingVariation(true)
        setSelectedItem(selectedVariation)
        if (!!onSelectCb) {
          onSelectCb(selectedVariation)
        } else {
          void router.replace(
            getProductPath(selectedVariation.alias),
            undefined,
            {
              scroll: true,
            },
          )
        }
      },
      [items, selectedItem?.uuid],
    )

    useEffect(() => {
      setIsFetchingVariation(false)
    }, [selected])

    return (
      <StyledVariations>
        {limitShowBoxes === undefined || items.length >= limitShowBoxes ? (
          <SelectView
            productName={productName}
            items={items}
            onSelect={onSelectHandle}
            isFetching={isFetchingVariation || isFetching}
            inCart={inCart}
            selected={selectedItem}
            withAnimatingLabel={withAnimatingLabel}
            closeMode={closeMode}
          />
        ) : (
          <BoxedView
            productName={productName}
            items={items}
            onSelect={onSelectHandle}
            isFetching={isFetchingVariation || isFetching}
            inCart={inCart}
            selected={selectedItem}
          />
        )}
      </StyledVariations>
    )
  },
)

Variations.displayName = "Variations"
export { Variations }
